
  import {defineComponent, ref, onMounted} from 'vue';
  import {ElConfigProvider} from 'element-plus';
  import useEmitter from '@/composables/Emmiter'
  import AuthService from "@/services/AuthService";
  import ApiService from '@/services/ApiService';
  import {useStore} from 'vuex';
  import {Actions} from "@/store/enums/StoreEnums";
  import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
  
  export default defineComponent({
    name: "filtro-config-app",
    components: {
      FilterBase,
      ElConfigProvider
    },
  
    setup(props, {emit}) {
      const store = useStore();
  
      const disabledClick = ref(false);
  
      let valorInicialCodConcessionaria;
      if (window.localStorage.getItem("id_usuario")) {
        valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
      } else {
        valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
      }
      const codConcessionariaSelected: any = ref(valorInicialCodConcessionaria ?? "");
  
      const userInfo = AuthService.getUsuarioAuth();
      const token = userInfo.token;
      const emitter = useEmitter();
  
      const activeModal = ref(false);
      const closeModal = ref(false);
      const label = ref("Faça filtros por concessionária, nome do serviço e situação");
  
      const lastEmitIsNull = ref(false);
      const showClose = ref(false)
      const optionsConcessionaria: any = ref([]);
  
      const loading: any = ref({
        concessionaria: false
      });
      const showGoToBack = ref(false);
  
      onMounted(() => {
        if (store.getters.concessionariasList.length > 0) {
          trataConcessionarias();
        }
      });
  
      store.watch(() => store.getters.concessionariasList, () => {
        trataConcessionarias();
      }, {deep: true});
  
      async function trataConcessionarias() {
        const concessionarias = store.getters.concessionariasList;
        optionsConcessionaria.value = concessionarias.map(concessionaria => {
          return {
            text: concessionaria.nomeFantasia,
            value: concessionaria.codConcessionaria
          }
        }).sort((concessionariaA, concessionariaB) => {
          if (!concessionariaA.disabled && concessionariaB.disabled) {
            return -1;
          }
          if (!concessionariaB.disabled && concessionariaA.disabled) {
            return 1;
          }
  
          if (concessionariaA.disabled && !concessionariaA.disabled) {
            if (concessionariaA.text < concessionariaB.text) {
              return -1;
            }
            if (concessionariaA.text > concessionariaB.text) {
              return 1;
            }
            return 0;
          }
          return 0;
        });
  
        const cacheConcessionariaSelected = store.getters.concessionariaSelectedInfo;
        if (cacheConcessionariaSelected.codConcessionaria) {
          emiteMudanca()
        } else {
          codConcessionariaSelected.value = optionsConcessionaria.value[0].disabled ? null : optionsConcessionaria.value[0].value;
          activeModal.value = !activeModal.value;
          showClose.value = false;
        }
      }
  
      async function emiteMudanca() {
        label.value = "Faça filtro por concessionária";
        showGoToBack.value = false;
        await ApiService.valideTokenTime();
  
        if (window.localStorage.getItem("id_usuario")) {
          window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
        } else {
          window.sessionStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
        }
  
        lastEmitIsNull.value = true;
        store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
        emitter.emit("filter-config-app", {
          codConcessionaria: codConcessionariaSelected.value,
        });
        closeModal.value = !closeModal.value;
        return;
      }
  
      return {
        activeModal,
        closeModal,
        showClose,
        loading,
        emiteMudanca,
        lastEmitIsNull,
        optionsConcessionaria,
        codConcessionariaSelected,
        showGoToBack,
        label,
        disabledClick,
      }
    }
  });
  